import React from 'react';
import PrivacyMain from './PrivacyMain';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

const Privacy = () => {
    return (
        <>
            <Header 
                parentMenu='Privacy Policy'
                headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
            />
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <PrivacyMain />
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default Privacy;

import React from "react";
import Breadcrumb from "../../Components/Breadcrumb";
import CTA from "../../Components/CTA";

// import bgImg from "../../assets/img/cta/cta-bg-2.png";
import shapeImg1 from "../../assets/img/price/price-shape-6.png";
import shapeImg2 from "../../assets/img/price/price-shape-7.png";
import priceImg1 from "../../assets/img/price/price-icon-1.png";
import priceImg2 from "../../assets/img/price/price-icon-2.png";
import priceImg3 from "../../assets/img/price/price-icon-3.png";

const PriceMain = () => {
  return (
    <main>
      <Breadcrumb pageTitle="Pricing" />
      <div className="price-2__area price-2__plr price-2__space">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="price-2__item fix">
                <div className="price-2__top-item">
                  <h4 className="price-2__top-title"> Basic </h4>{" "}
                  <i>
                    {" "}
                    $120 <span> /Yr</span>{" "}
                  </i>{" "}
                  <div className="price-2__shape">
                    <img src={shapeImg1} alt="" />
                    <div className="price-2__icon">
                      <img src={priceImg1} alt="" />
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="price-2__list">
                  <h4 className="price-2__list-title"> Basic Plan </h4>{" "}
                  <ul>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Single page website or portfolio
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Responsive to all screen type
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Single email functionality
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Web crawling
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Free SSL Certificate Included
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Custom domain name (Extra $12){" "}
                      </span>
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Server (Extra $12)
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Hosting (Extra $12)
                      </span>{" "}
                    </li>{" "}
                    
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>SEO Friendly
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>24/7 Support
                      </span>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div className="price-2__button">
                  <a className="main-btn-sm tp-btn-hover alt-color" href="/contact">
                    {" "}
                    <span> Get Your Plan</span>
                    <b></b>{" "}
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div
              className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="price-2__item active fix p-relative">
                <div className="price-2__item-shape d-none d-md-block">
                  <img src={shapeImg2} alt="" />
                </div>{" "}
                <div className="price-2__top-item">
                  <h4 className="price-2__top-title"> Advanced </h4>{" "}
                  <i>
                    {" "}
                    $299 <span> /Yr</span>{" "}
                  </i>{" "}
                  <div className="price-2__shape">
                    <img src={shapeImg1} alt="" />
                    <div className="price-2__icon">
                      <img src={priceImg2} alt="" />
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="price-2__list z-index-3">
                  <h4 className="price-2__list-title"> Advanced Plan </h4>{" "}
                  <ul>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Full functional Dynamic website 
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>With multiple inside pages
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Responsive to all screen type
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Email functionality
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Web crawling
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Free SSL Certificate Included
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Custom domain name (Extra $18)
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Server (Extra $18)
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Hosting (Extra $12)
                      </span>{" "}
                    </li>{" "}
                    
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>SEO Friendly
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>24/7 Support
                      </span>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div className="price-2__button">
                  <a
                    className="main-btn-sm white-bg tp-btn-hover alt-color-blue"
                    href="/contact"
                  >
                    {" "}
                    <span> Get Your Plan</span> <b> </b>
                  </a>
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div
              className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="price-2__item fix">
                <div className="price-2__top-item">
                  <h4 className="price-2__top-title"> Premium </h4>{" "}
                  <i>
                    {" "}
                    $599 <span> /Yr</span>{" "}
                  </i>{" "}
                  <div className="price-2__shape">
                    <img src={shapeImg1} alt="" />
                    <div className="price-2__icon">
                      <img src={priceImg3} alt="" />
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="price-2__list">
                  <h4 className="price-2__list-title"> Premium Plan </h4>{" "}
                  <ul>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Full functional Dynamic web application
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Responsive to all screen type
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Dashboard for web app{" "}
                      </span>
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Email functionality
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>WhatsApp functionality (Third-party charges vary)
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Web crawling
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Free SSL Certificate Included
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Custom domain name (Extra $24)
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Server (Extra $18)
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>Hosting (Extra $18)
                      </span>{" "}
                    </li>{" "}
                    
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>SEO Friendly
                      </span>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="far fa-check"> </i>24/7 Support
                      </span>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div className="price-2__button">
                  <a className="main-btn-sm tp-btn-hover alt-color" href="/contact">
                    {" "}
                    <span> Get Your Plan</span>
                    <b></b>{" "}
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
      <CTA />{" "}
    </main>
  );
};

export default PriceMain;

// src/pages/Home/HomeMain.js
import React from 'react';
import Banner from './HomeBanner';
import Brand from '../../Components/Brand';
import Feature from './FeatureSection';
import About from './AboutSection';
import Service from './ServiceSection';
import Project from './ProjectSection';
import Social from './SocialSection';
import Company from './CompanySection';
import CTA from './CtaSection';


const HomeMain = () => {
    return (
        <main>
            <Banner />

            <Brand />

            <Feature />

            <About />

            <Service />

            <Project />

            <Social />

            <Company />

            <CTA />

        </main>
    );
}

export default HomeMain;

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import  { Navigation, Pagination, Mousewheel } from 'swiper/modules';

import { Link } from 'react-router-dom';
import SectionTitle from '../../Components/SectionTitle';

import projectImg1 from '../../assets/img/project/pro-1.png';
import projectImg2 from '../../assets/img/project/pro-2.png';
import projectImg3 from '../../assets/img/project/pro-3.png';
import projectImg4 from '../../assets/img/project/pro-4.png';
import projectImg5 from '../../assets/img/project/pro-1.png';
import projectImg6 from '../../assets/img/project/pro-2.png';

SwiperCore.use([Navigation, Pagination, Mousewheel]);

const Project = () => {
    const sliderOption = {
        pagination: {
            el: '.project-slider-dots',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true
        },
        loop: true,
        spaceBetween: 30,
        slidesPerView: 3,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 3,
            },
        },
        mousewheel: true
    };

    return (
        <div className="project__area project-height pt-90">
            <div className="container">
                <div className="row align-items-end pb-60">
                    <div className="col-lg-6 col-md-9">
                        <SectionTitle 
                            sectionClass="project__section-box wow itfadeUp"
                            subTitleClass="section-subtitle char-anim"
                            titleClass="section-title char-anim"
                            subTitle="Our Portfolio"
                            Title="Showcasing Our Expertise in Digital Solutions" 
                        />
                    </div>
                    <div className="col-lg-6 col-md-3">
                        <div className="project__section-arrow d-flex align-items-center justify-content-end">
                            <div className="swiper-button swiper-button-prev">
                                <button><i className="far fa-arrow-left"></i></button>
                            </div>
                            <div className="swiper-button swiper-button-next">
                                <button><i className="far fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="swiper-container project__active">
                    <Swiper {...sliderOption} className="swiper-wrapper">
                        <SwiperSlide className="swiper-slide">
                        <div className="project__item">
                            <a href="https://jklungspecialityclinic.com/" target="_blank" rel="noopener noreferrer">
                                <img src={projectImg1} alt="" />
                            </a>
                        </div>

                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="project__item">
                            <a href="https://www.mallumechanics.uk/" target="_blank" rel="noopener noreferrer">
                                <img src={projectImg2} alt="" />
                            </a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="project__item">
                                <Link to="">
                                    <img src={projectImg3} alt="" />
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="project__item">
                            <a href="http://webfolioventure.com/" target="_blank" rel="noopener noreferrer">
                                <img src={projectImg4} alt="" />
                            </a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="project__item">
                            <a href="https://jklungspecialityclinic.com/" target="_blank" rel="noopener noreferrer">
                                    <img src={projectImg5} alt="" />
                                </a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="project__item">
                            <a href="https://www.mallumechanics.uk/" target="_blank" rel="noopener noreferrer">
                                <img src={projectImg6} alt="" />
                            </a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="project__item">
                                <Link to="">
                                    <img src={projectImg3} alt="" />
                                </Link>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="project-slider-dots"></div>
                </div>
            </div>
        </div>
    );
};

export default Project;

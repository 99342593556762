// src/pages/Home/Home.js
import React from 'react';
import HomeMain from './HomeMain';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

const Home = () => {
    return (
        <>
            <Header parentMenu='home' />
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <HomeMain />
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default Home;

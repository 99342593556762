import React from 'react';

const SectionTitle = (props) => {
    const {
        sectionClass,
        subTitleClass,
        titleClass,
        subTitle,
        Title
    } = props;

    return (
        <div className={sectionClass ? sectionClass : 'feature__section-box text-center pb-40'}>
            <h4 className={subTitleClass ? subTitleClass : 'section-subtitle title-anim'}>
                {subTitle ? subTitle : 'Our Outstanding Features'}
            </h4>
            <h3 className={titleClass ? titleClass : 'section-title title-anim'}>
                {Title ? Title : 'We Are the Premier Software Company for Your Business Needs'}
            </h3>
        </div>
    );
}

export default SectionTitle;

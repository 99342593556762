import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu } = props;
    const location = useLocation();

    return (
        <>
            <li className={parentMenu === 'home' ? 'active' : ''}>
                <Link to="/">Home</Link>
            </li>
            <li className={parentMenu === 'about' ? 'active' : ''}>
                <Link to="/about">About</Link>
            </li>
            <li className={parentMenu === 'service' ? 'active' : ''}>
                <Link to="/service">Service</Link>
            </li>
            <li className={parentMenu === 'price' ? 'active' : ''}>
                <Link to="/pricing">Pricing</Link>
            </li>
            <li className={location.pathname === "/contact" ? "active" : ""}>
                <Link to="/contact">Contact</Link>
            </li>
        </>
    );
}

export default MenuItems;

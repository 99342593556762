import React from 'react';
import ContactTwo from '../../Components/Contact/ContactTwo';
import Breadcrumb from '../../Components/Breadcrumb';
import CTA from '../../Components/CTA';

// import bgImg from '../../assets/img/cta/cta-bg-2.png';

const ContactMain = () => {
    return (
        <main>
            <Breadcrumb pageTitle="Contact" />
            <ContactTwo />
            <CTA />
        </main>
    );
}

export default ContactMain;

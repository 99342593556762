import React from "react";

import SocialFeed from "../../Components/SocialFeed";
import SectionTitle from "../../Components/SectionTitle";

const Social = () => {
  return (
    <div className="social__area social__mlr pt-100 pb-70">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div
            className="col-xxxl-7 col-xxl-8 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SectionTitle
              sectionClass="social__section-box pb-60 text-center section-title-fixed-width"
              subTitle="Social Media Feed"
              Title="Stay Connected with Us on Social Networks"
            />
          </div>{" "}
        </div>{" "}
        <SocialFeed />
      </div>{" "}
    </div>
  );
};

export default Social;

import React from 'react';
import { Link } from 'react-router-dom';

import Shape from '../../assets/img/service/sv-item-shape-1.png';
import Image from '../../assets/img/service/sv-icon-shape-1.png';

const SingleService = (props) => {
    const {
        itemClass,
        titleClass,
        descClass,
        shapeImg,
        serviceIcon,
        serviceIconBg,
        Title,
        Description,
  
        
    } = props;

    return (
        <div className={itemClass ? itemClass : 'service__item service__border-1 p-relative fix'}>
            <div className="service__shape">
                <img src={Shape ? Shape : shapeImg} alt="" />
            </div>
            <div className="service__icon-wrapper pb-25 p-relative">
                <div className="service__icon-bg">
                    <img src={serviceIconBg ? serviceIconBg : Image} alt="" />
                </div>
                <div className="service__inner-icon">
                    <span>
                        <a href="#0">
                            <i className={serviceIcon ? serviceIcon : 'fal fa-hand-holding-box'}></i>
                        </a>
                    </span>
                </div>
            </div>
            <div className="service__content">
                <h4 className={titleClass ? titleClass : 'service__title title-color-1'}>
                    <Link>
                        {Title ? Title : 'Get Latest Updates'}
                    </Link>
                </h4>
                <p className={descClass ? descClass : ''}>
                    {Description ? Description : 'Business building it before the tab providet management, Payroll & Worksite Services full-fledged.'}
                </p>
            </div>

        </div>
    );
}

export default SingleService;

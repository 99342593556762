import React, { useState, useRef } from "react";
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import shapeImg1 from "../../assets/img/contact/ct-shape-1.png";
import shapeImg2 from "../../assets/img/contact/ct-shape-2.png";
import shapeImg3 from "../../assets/img/contact/ct-shape-3.png";
import shapeImg4 from "../../assets/img/contact/ct-shape-4.png";

const ContactTwo = (props) => {
  
  const contactStyle = {
    paddingTop: '25px',
  }
  
  const { itemClass } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [popupVisible, setPopupVisible] = useState(false);
  const formRef = useRef(null);

  function sendEmail(e) {
    e.preventDefault();

    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData.entries());

    // Combine date and time into a single ISO string
    const combinedDateTime = new Date(startDate);
    combinedDateTime.setHours(startTime.getHours(), startTime.getMinutes());
    data.date = combinedDateTime.toISOString();

    axios.post('https://webfolioventure.com/send-email', data)
    .then((response) => {
      console.log(response.data);
      setPopupVisible(true);
      setTimeout(() => {
        setPopupVisible(false);
        formRef.current.reset(); // Refresh the page after the popup disappears
      }, 2000); // Show popup for 2 seconds
    })
    .catch((error) => {
      console.error(error);
    });
  

    
  }

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  const getMinTime = () => {
    const now = new Date();
    return isToday(startDate) ? now : new Date(now.setHours(0, 0, 0, 0));
  };
  
  return (
    <div
      className={
        itemClass
          ? itemClass
          : "contact__area contact__plr-2 mt-100 mb-100 p-relative fix"
      }
    >
      <div className="contact__shape-1 d-none d-lg-block">
        <img src={shapeImg1} alt="" />
      </div>{" "}
      <div className="contact__shape-2 d-none d-md-block">
        <img src={shapeImg2} alt="" />
      </div>{" "}
      <div className="contact__shape-3 d-none d-md-block">
        <img src={shapeImg3} alt="" />
      </div>{" "}
      <div className="contact__shape-4 d-none d-md-block">
        <img src={shapeImg4} alt="" />
      </div>{" "}
      <div className="contact__border">
        <div className="container" id="booking" >
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-7">
              <div className="contact__section-title pb-10">
                <h4 className="section-subtitle char-anim"> Get In Contact </h4>{" "}
                <h3 className="section-title char-anim">
                  {" "}
                  Feel free to get in touch <br />
                  Contact with me.{" "}
                </h3>{" "}
              </div>{" "}
              <div className="contact__text">
                <p className="char-anim-2">
                  {" "}
                  Contact us today to discuss how we can assist you with tailored IT design, development, 
                  and support services for your business.{" "}
                </p>{" "}
              </div>{" "}
              <div
                className="contact__form wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
              <form ref={formRef} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="postbox__contact-input">
                      <input type="text" name="firstName" placeholder="First Name" required />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="postbox__contact-input">
                      <input type="text" name="lastName" placeholder="Last Name" required />
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-6">
                    <div className="postbox__contact-input">
                      <input type="email" name="email" placeholder="Your Email" required />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="postbox__contact-input">
                      <input type="number" name="phone" placeholder="Your Phone number" required />
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-6">
                    <div className="postbox__contact-input">
                      <select name="service" required>
                        <option value="" disabled selected>Select Service</option>
                        <option value="Website Design & Development">Website Design & Development</option>
                        <option value="Portfolio">Portfolio</option>
                        <option value="Web Application">Web Application</option>
                        <option value="Dashboard for Web Application">Dashboard for Web Application</option>
                        <option value="Posters and Graphics">Posters and Graphics</option>
                        <option value="Hosting Your Website">Hosting Your Website</option>
                        <option value="Custom Domain">Custom Domain</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="postbox__contact-input">
                      <select name="plan" required>
                        <option value="" disabled selected>Select Plan</option>
                        <option value="Basic">Basic</option>
                        <option value="Advance">Advance</option>
                        <option value="Premium">Premium</option>
                      </select>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-4">
                    <div className="postbox__contact-input">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        minDate={new Date()}
                        dateFormat="P"
                        name="date"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="postbox__contact-input">
                      <DatePicker
                        selected={startTime}
                        onChange={(time) => setStartTime(time)}
                        showTimeSelect
                        showTimeSelectOnly
                        minTime={getMinTime()}
                        maxTime={new Date().setHours(23, 45)}
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        name="time"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="postbox__contact-input">
                      <select name="place" required>
                        <option value="" disabled selected>Select Location</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="UK">UK</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-12">
                    <div className="postbox__contact-textarea">
                      <textarea name="message" placeholder="Message" required></textarea>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-12">
                    <div className="contact__button wow animate__fadeInUp" data-wow-duration="1.1s" style={contactStyle}>
                      <button type="submit" className="main-btn-sm tp-btn-hover alt-color">
                        <span>Send Request</span>
                        <b></b>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              {popupVisible && (
                <div className="popup">
                  <span>Request sent successfully!✅</span>
                </div>
              )}
              </div>{" "}
            </div>{" "}
            <div className="col-xl-4 col-lg-5">
              <div className="contact__left-side">
                <div
                  className="contact__item d-flex align-items-center wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  <div className="contact__icon">
                    <span>
                      {" "}
                      <a href="#0">
                        {" "}
                        <i className="fal fa-phone-alt"> </i>
                      </a>{" "}
                    </span>{" "}
                  </div>{" "}
                  <div className="contact__content">
                    <h4 className="contact__title-sm"> Call This Now </h4>{" "}
                    <span>
                      {" "}
                      <a href="tel:+447436208590">
                        {" "}
                        +44 7436 208590<br />
                      </a>
                    </span>
                  </div>{" "}
                </div>{" "}
                <div
                  className="contact__item d-flex align-items-center wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  <div className="contact__icon">
                    <span>
                      {" "}
                      <a href="#0">
                        {" "}
                        <i className="fal fa-envelope-open-text"> </i>
                      </a>{" "}
                    </span>{" "}
                  </div>{" "}
                  <div className="contact__content">
                    <h4 className="contact__title-sm"> Your Message </h4>{" "}
                    <span>
                      {" "}
                      <a href="mailto:amar@mail.com">
                        {" "}
                        webfolioventure@gmail.com <br />
                      </a>
                    </span>
                  </div>{" "}
                </div>{" "}
                <div
                  className="contact__item d-flex align-items-center wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  <div className="contact__icon">
                    <span>
                      {" "}
                      <a href="#0">
                        {" "}
                        <i className="fal fa-map-marker-check"> </i>
                      </a>{" "}
                    </span>{" "}
                  </div>{" "}
                  <div className="contact__content">
                    <h4 className="contact__title-sm"> Your location </h4>{" "}
                    <span>
                      {" "}
                      <a href="#0">
                        {" "}
                        Chennai, India <br />
                      </a>
                    </span>
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default ContactTwo;

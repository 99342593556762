import React from 'react';
import Breadcrumb from '../../Components/Breadcrumb';
import CTA from '../../Components/CTA';

const PrivacyMain = () => {
    const headingStyle = {
        color: 'white',
        marginBottom: '50px',
        textAlign:'center',
    
    };

    const subheadingStyle = {
        color: 'white',
        marginBottom: '15px',
        // fontsize:'20px',
    };

    const paragraphStyle = {
        color: 'white',
        marginBottom: '10px',
        fontsize:'24px',
        lineHeight:'50px'
    };
    const strongstyle ={
        color: '#0CC0DF',

    }
    // const listStyle ={
    //     listStyleType: 'none',
        
    // }
    const itemStyle ={
        color: '#0CC0DF',
    }

    return (
        <main>
            <Breadcrumb pageTitle="Privacy Policy" />
            <div className="service__area service__plr mt-100 mb-20 p-relative">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="service__item service__item-bg p-relative fix shape-none">
                                <h2 style={headingStyle}>Privacy Policy</h2>

                                <h4 style={subheadingStyle}>Introduction</h4>
                                <p style={paragraphStyle}>At Webfolio Venture, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information.</p>

                                <h4 style={subheadingStyle}>Information Collection</h4>
                                <ol style={paragraphStyle}>
                                    <li><strong style={strongstyle}>Personal Information:</strong> We collect personal information that you provide to us directly, such as your name, email address, phone number, and payment details.</li>
                                    <li><strong style={strongstyle}>Usage Data:</strong> We may collect information on how our services are accessed and used, such as your IP address, browser type, and browsing history.</li>
                                </ol>

                                <h4 style={subheadingStyle}>Use of Information</h4>
                                <ol style={paragraphStyle}>
                                    <li><strong style={strongstyle}>Service Delivery:</strong> To provide, maintain, and improve our services.</li>
                                    <li><strong style={strongstyle}>Communication:</strong> To contact you with updates, promotional materials, and other information that may be of interest to you.</li>
                                    <li><strong style={strongstyle}>Payments:</strong> To process your payments and manage your account.</li>
                                </ol>

                                <h4 style={subheadingStyle}>Data Sharing</h4>
                                <ol style={paragraphStyle}>
                                    <li><strong style={strongstyle}>Third-Party Services:</strong> We may share your information with third-party services to facilitate our services, such as payment processors and hosting providers.</li>
                                    <li><strong style={strongstyle}>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid requests by public authorities.</li>
                                </ol>

                                <h4 style={subheadingStyle}>Data Security</h4>
                                <p style={paragraphStyle}>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>

                                <h4 style={subheadingStyle}>Your Rights</h4>
                                <p style={paragraphStyle}>You have the right to access, update, or delete your personal information. If you wish to exercise any of these rights, please contact us.</p>

                                <h4 style={subheadingStyle}>Changes to This Policy</h4>
                                <p style={paragraphStyle}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.</p>


                                <h4 style={subheadingStyle}>Contact Us</h4>
                                <p style={paragraphStyle}>If you have any questions about these Our Privacy Policy, please contact us at:</p>
                                <p style={paragraphStyle}><i style={itemStyle} className="fal fa-phone-alt"></i> +44 7436 208590</p>
                                <p style={paragraphStyle}><i style={itemStyle} className="fal fa-envelope"></i> webfolioventure@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTA />
        </main>
    );
}

export default PrivacyMain;

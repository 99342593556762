import React from 'react';
import Breadcrumb from '../../Components/Breadcrumb';
import CTA from '../../Components/CTA';

const TermsMain = () => {
    const headingStyle = {
        color: 'white',
        marginBottom: '50px',
        textAlign:'center',
    
    };

    const subheadingStyle = {
        color: 'white',
        marginBottom: '15px',
        // fontsize:'20px',
    };

    const paragraphStyle = {
        color: 'white',
        marginBottom: '10px',
        fontsize:'24px',
        lineHeight:'50px'
    };
    const strongstyle ={
        color: '#0CC0DF',

    }
    const listStyle ={
        listStyleType: 'none',
        
    }
    const itemStyle ={
        color: '#0CC0DF',
    }

    return (
        <main>
            <Breadcrumb pageTitle="Terms & Conditions" />
            <div className="service__area service__plr mt-100 mb-20 p-relative">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="service__item service__item-bg p-relative fix shape-none">
                                <h2 style={headingStyle}>Terms & Conditions</h2>

                                <h4 style={subheadingStyle}>Introduction</h4>
                                <p style={paragraphStyle}>Welcome to Webfolio Venture. By using our services, you agree to be bound by these terms and conditions. Please read them carefully.</p>

                                <h4 style={subheadingStyle}>Payment Terms</h4>
                                <ol style={paragraphStyle}>
                                    <li><strong style={strongstyle}>Initial Payment:</strong> Research and development for your project will commence after the first advance payment is received.</li>
                                    <li><strong style={strongstyle}>Payment Structure:</strong> The total project cost will be split according to the overall budget. 
                                    <br/> <b>Payments are due at specific milestones:</b>
                                        <ul style={listStyle }>
                                            <li><i style={itemStyle} className="far fa-angle-double-right"> </i> After each demo session, the client must pay a portion of the agreed budget.</li>
                                            <li><i style={itemStyle} className="far fa-angle-double-right"> </i> Final payments for hosting and custom domain charges will vary based on the specific requirements of each project.</li>
                                        </ul>
                                    </li>
                                    <li><strong style={strongstyle}>Yearly Charges:</strong> Domain and hosting services are subject to annual charges.</li>
                                </ol>

                                <h4 style={subheadingStyle}>Hosting and Server</h4>
                                <ol style={paragraphStyle}>
                                    <li><strong style={strongstyle}>Using Our Server:</strong>
                                        <ul style={listStyle }>
                                            <li><i style={itemStyle} className="far fa-angle-double-right"> </i> If you choose to host your website on our servers, we will provide full support as long as you remain tied up with us.</li>
                                            <li><i style={itemStyle} className="far fa-angle-double-right"> </i> Annual charges for hosting and domain services apply.</li>
                                        </ul>
                                    </li>
                                    <li><strong style={strongstyle}>Using a Separate Server:</strong>
                                        <ul style={listStyle }>
                                            <li><i style={itemStyle} className="far fa-angle-double-right"> </i> If you prefer a separate server, we will assist you in setting it up.</li>
                                            <li><i style={itemStyle} className="far fa-angle-double-right"> </i> Once the setup is complete, we will transfer all data to the client and cease to provide any further services for that server.</li>
                                            <li><i style={itemStyle} className="far fa-angle-double-right"> </i> The client will be responsible for managing their separate server post-setup.</li>
                                        </ul>
                                    </li>
                                </ol>

                                <h4 style={subheadingStyle}>Service Limitations</h4>
                                <ol style={paragraphStyle}>
                                    <li><strong style={strongstyle}>Data Transfer:</strong> In the event that you do not want to use our server, we will transfer your data to you, after which our service and support will be discontinued.</li>
                                    <li><strong style={strongstyle}>Support:</strong> Full support is only available if you use our server. If you choose to use a separate server, you will not receive any ongoing support from us post-setup.</li>
                                </ol>

                                <h4 style={subheadingStyle}>Contact Us</h4>
                                <p style={paragraphStyle}>If you have any questions about these Terms & Conditions, please contact us at:</p>
                                <p style={paragraphStyle}><i style={itemStyle} className="fal fa-phone-alt"></i> +44 7436 208590</p>
                                <p style={paragraphStyle}><i style={itemStyle} className="fal fa-envelope"></i> webfolioventure@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTA />
        </main>
    );
}

export default TermsMain;

import React from "react";
import Breadcrumb from "../../Components/Breadcrumb";
import Contact from "../../Components/Contact";
import CTA from "../../Components/CTA";
import Brand from "../../Components/Brand";

import aboutImg from "../../assets/img/about/about-6.png";
// import bgImg from "../../assets/img/cta/cta-bg-2.png";
// // import videoBg from "../../assets/img/video/video-bg-4.png";
// // import videoImg from "../../assets/img/video/video-bg-6.png";

import Feature from "./FeatureSection";

const AboutMain = () => {
  return (
    <main>
      <Breadcrumb pageTitle="About Us" />
      <Brand itemClass="brand-2__area brand-2__space" />

      <div className="about-3__area about-3__space about-3__plr about-3__plr-2">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div
              className="col-xl-6 col-lg-6 col-md-12 mb-70 wow animate__fadeInLeft"
              data-wow-duration="1.1s"
            >
              <div className="about-3__item-img p-relative">
                <img src={aboutImg} alt="" />
                {/* <div className="about-3__button about-3__button-wrapper d-flex align-items-center">
                  <div className="about-3__video">
                    <a href="#0">
                      {" "}
                      <i className="fas fa-play"> </i>
                    </a>
                  </div>{" "}
                  <div className="about-3__video-text">
                    <a href="#0">
                      {" "}
                      <span> Watch Video </span>
                    </a>
                  </div>{" "}
                </div>{" "} */}
              </div>{" "}
            </div>{" "}
            <div
              className="col-xl-6 col-lg-6 col-md-12 mb-70 wow animate__fadeInRight"
              data-wow-duration="1.1s"
            >
              <div className="about-3__item about-3__wrapper-item">
                <div className="about-3__section-title pb-10">
                  <h4 className="section-blue-subtitle char-anim">
                    {" "}
                    Welcome to Webfolio Venture{" "}
                  </h4>{" "}
                  <h3 className="section-title char-anim">
                    {" "}
                    Innovating Excellence in <br/> 
                    <i> Digital Solutions</i>.{" "}
                  </h3>{" "}
                </div>{" "}
                <div className="about-3__content">
                  <div className="about-3__text pb-7">
                    <p className="char-anim-2">
                      {" "}
                      Welcome to Webfolio Venture, where innovation meets excellence in digital solutions. 
                      We are a dedicated team of creative professionals and tech enthusiasts, passionate about transforming ideas
                       into impactful online experiences. Our diverse range of services ensures that we cater to all your digital 
                       needs under one roof.
                      {" "}
                    </p>{" "}
                  </div>{" "}
                  <div className="about-3__content-list about-3__content-list-2">
                    <ul>
                      <li>
                        {" "}
                        <i className="far fa-angle-double-right"> </i>
                        <span>
                        Tailored IT solutions designed to enhance your digital presence and streamline operations.
                        </span>{" "}
                      </li>{" "}
                      <li>
                        {" "}
                        <i className="far fa-angle-double-right"> </i>
                        <span>Efficiently manage your business with our strategic planning and execution capabilities. </span>{" "}
                      </li>{" "}
                      <li>
                        {" "}
                        <i className="far fa-angle-double-right"> </i>
                        <span>
                        From management to technical support, we provide comprehensive services to meet all your business needs.
                        {" "}
                        </span>{" "}
                      </li>{" "}
                    </ul>{" "}
                  </div>{" "}
                  {/* <div className="about-3__button">
                    <a className="main-btn-sm tp-btn-hover alt-color" href="#0">
                      <span> About More </span>
                      <b></b>
                    </a>{" "}
                  </div>{" "} */}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>

      <Feature />

      <Contact />

      <CTA />
    </main>
  );
};

export default AboutMain;

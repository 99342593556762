import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
import WOW from 'wowjs';

// Custom Components
import Preloader from "./Components/Preloader";
import ScrollToTop from './Components/ScrollTop';
import LoadTop from './Components/ScrollTop/LoadTop';
import Home from './pages/home';
import About from './pages/about';
import Service from "./pages/service";
import Price from "./pages/price";
import Contact from "./pages/contact";
import Terms from "./pages/terms"
import Privacy from "./pages/privacy"

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleRouteChange = (location) => {
    const wow = new WOW.WOW({
      live: false
    });
    wow.init();
  };

  const location = useLocation();

  useEffect(() => {
    handleRouteChange(location);
  }, [location]);

  useEffect(() => {
    // Simulate data loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <div className='App'>
      {isLoading ? <Preloader /> : ''}
      <>
        <ScrollToTop />
        <LoadTop />
        {/* Add your Routes here */}
        <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/service' element={<Service/>} />
        <Route path='/pricing' element={<Price/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/terms&conditions' element={<Terms/>} />
        <Route path='/privacypolicy' element={<Privacy/>} />
        </Routes>
      </>
    </div>
  );
};

export default App;

import React from 'react';

const Topbar = (props) => {
    return (
        <>
            <div className="header-top__area header-top__bg header-top__plr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-6 col-lg-4 col-sm-4">
                            <div className="header-top__lang d-none d-sm-block">
                                <ul>
                                    <li>
                                        <a href="#0">
                                            English <i className="fal fa-angle-down"></i>
                                        </a>
                                        <ul>
                                            <li><a href="#0">Arabic</a></li>
                                            <li><a href="#0">Spanish</a></li>
                                            <li><a href="#0">Bangla</a></li>
                                            <li><a href="#0">English</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-sm-8">
                            <div className="header-top__social text-end">
                                <span>Visit our social pages: </span>
                                <a href="#0"><i className="fab fa-instagram"></i></a>
                                <a href="#0"><i className="fab fa-facebook-f"></i></a>
                                <a href="#0"><i className="fab fa-linkedin"></i></a>
                                <a href="#0"><i className="fas fa-envelope"></i></a>      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Topbar;

import React from 'react';
import TermsMain from './TermsMain';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

const Terms = () => {
    return (
        <>
            <Header 
                parentMenu='Terms & Conditions'
                headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
            />
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <TermsMain />
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default Terms;

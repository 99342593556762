import React from "react";

import SectionTitle from "../../Components/SectionTitle";
import SingleFeature from "../../Components/Feature";

import featureImg1 from "../../assets/img/feature/fea-1.png";
import featureImg2 from "../../assets/img/feature/fea-2.png";
import featureImg3 from "../../assets/img/feature/fea-3.png";

const Feature = () => {
  return (
    <div className="feature__area feature__mlr pt-100 pb-70">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div
            className="col-xl-6 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SectionTitle />
          </div>{" "}
        </div>{" "}
        <div className="row">
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleFeature
              Image={featureImg1}
              Title="Experienced Team"
              Description="Our skilled professionals bring years of experience and a wealth of knowledge to every project"
              btnText="See More"
              btnURL="/service-details"
            />
          </div>{" "}
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleFeature
              Image={featureImg3}
              Title="Tailored Solutions"
              Description="We understand that every business is unique, and we provide solutions that are specifically designed to meet your needs."
              btnText="See More"
              btnURL="/service-details"
            />
          </div>{" "}
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleFeature
              Image={featureImg2}
              Title="Customer Satisfaction"
              Description="Your satisfaction is our top priority, and we strive to build long-term relationships based on trust and mutual success"
              btnText="See More"
              btnURL="/service-details"
            />
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Feature;

import React from "react";
// import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import companyImg from "../../assets/img/company/company-1.png";
import tabImg1 from "../../assets/img/company/company-tab-1.png";
import tabImg2 from "../../assets/img/company/company-tab-1.png";
import tabImg3 from "../../assets/img/company/company-tab-1.png";

const Company = () => {
  let tab1 = "Research",
    tab2 = "Design",
    tab3 = "Delivery";
  const tabStyle = "nav nav-tab";

  return (
    <div className="company__area pb-100">
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-xl-6 wow animate__fadeInLeft"
            data-wow-duration="1.1s"
          >
            <div className="company__left-img text-center text-xl-start">
              <img src={companyImg} alt="" />
            </div>{" "}
          </div>{" "}
          <div
            className="col-xl-6 wow animate__fadeInRight"
            data-wow-duration="1.1s"
          >
            <div className="company__section-box">
              <h4 className="section-subtitle char-anim">
                {" "}
                Our Solutions{" "}
              </h4>{" "}
              <h3 className="section-title pb-10 char-anim">
                {" "}
                Unique Solutions Tailored for Every Business{" "}
              </h3>{" "}
              <p className="char-anim-2">
                {" "}
                At Webfolio Venture, we specialize in delivering bespoke digital solutions tailored to meet the specific needs of each company. From crafting compelling videos to localizing 
                content for diverse regions, we empower businesses to save resources, time, and energy efficiently.{" "}
              </p>{" "}
            </div>{" "}
            <Tabs>
              <div className="company__service-tab">
                <TabList className={tabStyle}>
                  <Tab>
                    {" "}
                    <button className="btn-color-1">
                      {" "}
                      <span> {tab1} </span>
                    </button>{" "}
                  </Tab>{" "}
                  <Tab>
                    {" "}
                    <button className="btn-color-2">
                      {" "}
                      <span> {tab2} </span>
                    </button>{" "}
                  </Tab>{" "}
                  <Tab>
                    {" "}
                    <button className="btn-color-3">
                      {" "}
                      <span> {tab3} </span>
                    </button>{" "}
                  </Tab>{" "}
                </TabList>{" "}
                <div className="tab-content" id="myTabContent">
                  <TabPanel>
                    <div className="company__tab-content-wrapper d-flex align-items-center">
                      <div className="company__tab-icon">
                        <span>
                          {" "}
                          <img src={tabImg1} alt="" />{" "}
                        </span>{" "}
                      </div>{" "}
                      <div className="company__tab-text">
                        <h4> Step-by-step Process for Creating Solutions </h4>{" "}
                        <span>Our research phase involves a structured approach to problem-solving. We generate ideas, create prototypes, 
                          and refine solutions to address your business challenges effectively.</span>{" "}
                      </div>{" "}
                    </div>{" "}
                  </TabPanel>{" "}
                  <TabPanel>
                    <div className="company__tab-content-wrapper d-flex align-items-center">
                      <div className="company__tab-icon">
                        <span>
                          {" "}
                          <img src={tabImg2} alt="" />{" "}
                        </span>{" "}
                      </div>{" "}
                      <div className="company__tab-text">
                        <h4> Step-by-step Method for Gathering and Analyzing Information</h4>{" "}
                        <span>At Webfolio Venture, our design process begins with a meticulous approach to gathering and analyzing information. 
                          We delve deep into your business goals and user needs to ensure our solutions are tailored precisely to your requirements.</span>{" "}
                      </div>{" "}
                    </div>{" "}
                  </TabPanel>{" "}
                  <TabPanel>
                    <div className="company__tab-content-wrapper d-flex align-items-center">
                      <div className="company__tab-icon">
                        <span>
                          {" "}
                          <img src={tabImg3} alt="" />{" "}
                        </span>{" "}
                      </div>{" "}
                      <div className="company__tab-text">
                        <h4> Efficient Order Fulfillment Process</h4>{" "}
                        <span> When it comes to delivery, we prioritize accuracy and completeness. We ensure every order is processed 
                          efficiently, meeting your expectations and deadlines seamlessly.</span>{" "}
                      </div>{" "}
                    </div>{" "}
                  </TabPanel>{" "}
                </div>{" "}
              </div>{" "}
            </Tabs>{" "}
            {/* <div className="company__button">
              <Link
                to="/service-details"
                className="main-btn tp-btn-hover alt-color"
              >
                <span> Read More </span> <b> </b>{" "}
              </Link>{" "}
            </div>{" "} */}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Company;

import React from 'react';
import SingleService from '../../Components/Service';
import Breadcrumb from '../../Components/Breadcrumb';
import CTA from '../../Components/CTA';

import serviceIconBg1 from '../../assets/img/service/sv-icon-shape-13.png';
import serviceIconBg2 from '../../assets/img/service/sv-icon-shape-14.png';
import serviceIconBg3 from '../../assets/img/service/sv-icon-shape-15.png';
import serviceIconBg4 from '../../assets/img/service/sv-icon-shape-16.png';
import serviceIconBg5 from '../../assets/img/service/sv-icon-shape-17.png';
import serviceIconBg6 from '../../assets/img/service/sv-icon-shape-18.png';
import serviceIconBg7 from '../../assets/img/service/sv-icon-shape-19.png';
import serviceIconBg8 from '../../assets/img/service/sv-icon-shape-20.png';
import serviceIconBg12 from '../../assets/img/service/sv-icon-shape-23.png';

const services = [
    {
        serviceIcon: "fal fa-paint-brush-alt",
        serviceIconBg: serviceIconBg1,
        title: "Website Design",
        description: "Create visually stunning and functional websites tailored to your brand. Our designs focus on user experience and conversion optimization.",
        btnText: "Read More",
        colorClass: "color-1",
        btnClass: "service__link-color-1"
    },
    {
        serviceIcon: "fal fa-tv",
        serviceIconBg: serviceIconBg2,
        title: "Web App Development",
        description: "Develop custom web applications that meet your specific business needs. From complex functionalities to intuitive interfaces, we deliver scalable solutions.",
        btnText: "Read More",
        colorClass: "color-2",
        btnClass: "service__link-color-2"
    },
    {
        serviceIcon: "fal fa-portrait",
        serviceIconBg: serviceIconBg3,
        title: "Portfolios",
        description: "Craft compelling portfolios that showcase your work and expertise. We emphasize creative presentation and seamless navigation for your portfolio.",
        btnText: "Read More",
        colorClass: "color-3",
        btnClass: "service__link-color-3"
    },
    {
        serviceIcon: "fal fa-photo-video",
        serviceIconBg: serviceIconBg4,
        title: "Posters and Graphics",
        description: "Design eye-catching posters and graphics that captivate your audience. Our visuals are tailored to convey your message effectively and attract attention.",
        btnText: "Read More",
        colorClass: "color-4",
        btnClass: "service__link-color-4"
    },
    {
        serviceIcon: "fas fa-bullhorn",
        serviceIconBg: serviceIconBg5,
        title: "SEO",
        description: "Improve your search engine visibility and drive organic traffic to your website. Our SEO strategies focus on keyword optimization and content quality.",
        btnText: "Read More",
        colorClass: "color-5",
        btnClass: "service__link-color-5"
    },
    {
        serviceIcon: "fal fa-user-headset",
        serviceIconBg: serviceIconBg6,
        title: "Strategy Consulting",
        description: "Provide expert guidance on business strategy and digital transformation. Our consulting services help you achieve long-term growth and profitability.",
        btnText: "Read More",
        colorClass: "color-6",
        btnClass: "service__link-color-6"
    },
    {
        serviceIcon: "fas fa-chart-pie",
        serviceIconBg: serviceIconBg12,
        title: "Dashboard For Web",
        description: "Develop intuitive dashboards to visualize data and monitor key metrics. Our web dashboards provide actionable insights for informed decision-making.",
        btnText: "Read More",
        colorClass: "color-12",
        btnClass: "service__link-color-12"
    },
    {
        serviceIcon: "fas fa-wifi",
        serviceIconBg: serviceIconBg7,
        title: "Web Hosting",
        description: "Offer reliable and secure web hosting services for your website. Our hosting solutions ensure high performance, uptime, and data protection.",
        btnText: "Read More",
        colorClass: "color-7",
        btnClass: "service__link-color-7"
    },
    {
        serviceIcon: "fas fa-compass",
        serviceIconBg: serviceIconBg8,
        title: "Custom Domain Setup",
        description: "Set up personalized domain names that reflect your brand identity. Our domain setup services ensure a professional online presence.",
        btnText: "Read More",
        colorClass: "color-8",
        btnClass: "service__link-color-8"
    },
];

const ServiceMain = () => {
    return (
        <main>
            <Breadcrumb pageTitle="Service" />
            <div className="service__area service__plr mt-100 mb-20 p-relative">
                <div className="container-fluid">
                    <div className="row">
                        {services.map((service, index) => (
                            <div 
                                className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" 
                                data-wow-duration="1.1s" 
                                key={index}
                            >
                                <SingleService
                                    itemClass="service__item service__item-bg p-relative fix shape-none"
                                    btnClass={`service__link ${service.btnClass}`}
                                    titleClass={`service__title ${service.colorClass}`}
                                    descClass="text-white"
                                    serviceIcon={service.serviceIcon}
                                    serviceIconBg={service.serviceIconBg}
                                    Title={service.title}
                                    Description={service.description}
                                    btnText={service.btnText}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <CTA />
        </main>
    );
}

export default ServiceMain;

import React from "react";
import { Link } from "react-router-dom";

import bgImg from "../../assets/img/cta/cta-bg.png";

const CTA = (props) => {
  const { ctaBG, ctaClass, Title, Description, btnText, btnURL } = props;

  return (
    <div
      className={
        ctaClass
          ? ctaClass
          : "cta__area cta__bg cta__bg-2 cta__plr p-relative wow animate__fadeInUp"
      }
      data-wow-duration="1.1s"
      style={{
        backgroundImage: `url(${ctaBG ? ctaBG : bgImg})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="cta__section-wrapper text-center">
            <div className="cta__section-box">
                <h4
                  className="cta__title  wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  {Title ? Title : "Let's Build Amazing Websites and Portfolios Together"}
                </h4>
                <p
                  className=" wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  {Description
                    ? Description
                    : (
                      <>
                        Join us on a journey to digital excellence. <br/>
                        At Webfolio Venture, we're committed to transforming your digital vision into reality. <br/>
                        Contact us today to discuss how we can help your business thrive in the digital age. <br/>
                      </>
                    )}
                </p>
              </div>
              {" "}
              <div
                className="cta__btn wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                <Link
                  to={`/${btnURL ? btnURL : "contact"}`}
                  className="white-btn tp-btn-hover alt-color"
                >
                  {" "}
                  <span> {btnText ? btnText : "Get Started"} </span>
                  <b></b>{" "}
                </Link>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default CTA;

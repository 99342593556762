import React from 'react';
import featureImg from '../../assets/img/feature/fea-1.png';

const SingleFeature = (props) => {
    const {
        Title = 'Online Marketing', // Default Title
        Description = 'Business is the knowledge or master event. Identify the error of the website coding page speed.', // Default Description
        Image = featureImg, // Default Image
        
    } = props;

    return (
        <div className="feature__item feature__color-3 text-center">
            <div className="feature__icon z-index">
                <span>
                    <img src={Image} alt={Title} />
                </span>
            </div>
            <div className="feature__content">
                <h4 className="feature__title">{Title}</h4>
                <p>{Description}</p>
            </div>
            
        </div>
    );
}

export default SingleFeature;

import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/img/logo/footer-logo.png";
import footerBG from "../../assets/img/footer/footer-bg.jpg";

const Footer = (props) => {
  const { footerLogo, footerClass } = props;

  return (
    <footer>
      {/* footer-area-start */}
      <div
        className={footerClass ? footerClass : "footer__area footer__plr"}
        style={{ backgroundImage: `url(${footerBG})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 wow tpfadeUp"
              data-wow-duration=".9s"
              data-wow-delay=".3s"
            >
              <div className="footer__widget footer-col-1">
                <div className="footer__logo">
                  <Link to="/">
                    <img src={footerLogo ? footerLogo : Logo} alt="" />
                  </Link>
                </div>
                <div className="footer__content">
                  <div className="footer__text">
                    <p>
                    Innovative Strategies That Work<br/>
                    At Webfolio Venture, we innovate practical IT solutions that drive business growth.
                    </p>
                  </div>
                  <div className="footer__number-wrapper d-flex align-items-center">
                    <div className="footer__number-icon">
                      <span>
                        <i className="fal fa-envelope"></i>
                      </span>
                    </div>
                    <div className="footer__number-info">
                      <span>Contact us:</span>
                      <a href="mailto:webfolioventure@gmail.com" target="_blank" rel="noopener noreferrer">webfolioventure@gmail.com</a>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow tpfadeUp"
              data-wow-duration=".9s"
              data-wow-delay=".5s"
            >
              <div className="footer__widget footer-col-2">
                <h4 className="footer__title"><Link to="#">Service</Link></h4>
                <div className="footer__menu-list">
                  <ul>
                    <li><Link to="">Website Design</Link></li>
                    <li><Link to="#">Web Application Development</Link></li>
                    <li><Link to="#">Portfolios</Link></li>
                    <li><Link to="#">Posters and Graphics</Link></li>
                    <li><Link to="#">SEO </Link></li>
                    <li><Link to="#">Strategy Consulting</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow tpfadeUp"
              data-wow-duration=".9s"
              data-wow-delay=".7s"
            >
              <div className="footer__widget footer-col-3">
                <h4 className="footer__title"><Link to="#">Quick Link</Link></h4>
                <div className="footer__menu-list">
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/service">Service</Link></li>
                    <li><Link to="/pricing">Pricing</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 wow tpfadeUp"
              data-wow-duration=".9s"
              data-wow-delay=".9s"
            >
              <div className="footer__widget footer-col-4">
                <h4 className="footer__title"><Link to="#">Stay Updated</Link></h4>
                <div className="footer__content">
                  <p>Explore Our Unique Approach.<br/>Discover how our innovative IT solutions can benefit your business.</p>
                </div>
                {/* <div className="footer__input">
                  <form action="#">
                    <input type="email" placeholder="Your email" />
                    <button className="subscribe-btn">Subscribe</button>
                  </form>
                </div> */}
                <div className="footer__social-box">
                  {/* <span>Social Media:</span> */}
                  <a href="https://www.instagram.com/webfolioventure" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                  <a href="https://www.facebook.com/profile.php?id=61558191785271" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                  <a href="https://www.linkedin.com/company/webfolio-venture/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                  <a href="mailto:webfolioventure@gmail.com" target="_blank" rel="noopener noreferrer"><i className="fas fa-envelope"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer-area-end */}

      {/* copyright-area-start */}
      <div className="copyright__area copyright__plr black-bg-3">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div
              className="col-md-6 wow tpfadeUp"
              data-wow-duration=".9s"
              data-wow-delay=".3s"
            >
              <div className="copyright__text">
                <span>Copyright WebFolio Venture 2024, All Right Reserved</span>
              </div>
            </div>
            <div
              className="col-md-6 wow tpfadeUp"
              data-wow-duration=".9s"
              data-wow-delay=".5s"
            >
              <div className="copyright__item text-start text-md-end">
                <span><Link to="/pricing">Pricing</Link></span>
                <span><Link to="/terms&conditions">Terms & Conditions</Link></span>
                <span><Link to="/privacypolicy">Privacy Policy</Link></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* copyright-area-end */}
    </footer>
  );
};

export default Footer;

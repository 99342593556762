import React from "react";

import aboutImg from "../../assets/img/about/about-1.png";
import shapeImg1 from "../../assets/img/about/about-shape-1.png";
import shapeImg2 from "../../assets/img/about/about-shape-2.png";

const About = () => {


  return (
    <div className="about__area pb-100 p-relative">
      
      <div className="about__shape-1 d-none d-md-block">
        <img src={shapeImg1} alt="" />
      </div>{" "}
      <div className="about__shape-2 d-none d-xl-block">
        <img src={shapeImg2} alt="" />
      </div>{" "}
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInLeft"
            data-wow-duration="1.1s"
          >
            <div className="about__img">
              <img src={aboutImg} alt="" />
            </div>{" "}
          </div>{" "}
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInRight"
            data-wow-duration="1.1s"
          >
            <div className="about__section-box pb-10">
              <h4 className="section-subtitle char-anim">Our Highlights </h4>{" "}
              <h3 className="section-title char-anim">
                {" "}
                Your Digital Success Starts Here{" "}
              </h3>{" "}
            </div>{" "}
            <div className="about__content">
              <p className="char-anim-2">
                {" "}
                Business Tailored IT Design, Management & Support Services{" "}
              </p>{" "}
            </div>{" "}
            <div className="about__list-item">
              <ul>
                <li>
                  <i className="far fa-check"> </i>{" "}
                  <span> Custom Website Development </span>{" "}
                </li>{" "}
                <li>
                  <i className="far fa-check"> </i>{" "}
                  <span> Comprehensive Portfolio Management </span>{" "}
                </li>{" "}
                <li>
                  <i className="far fa-check"> </i>{" "}
                  <span> Strategic Digital Marketing </span>{" "}
                </li>{" "}
                <li>
                  <i className="far fa-check"> </i>{" "}
                  <span> Reliable Technical Support </span>{" "}
                </li>{" "}
                {/* <li>
                  <i className="far fa-check"> </i>{" "}
                  <span> Business management </span>{" "}
                </li>{" "}
                <li>
                  <i className="far fa-check"> </i>{" "}
                  <span> Agency in this solution. </span>{" "}
                </li>{" "} */}
              </ul>{" "}
            </div>{" "}
            <div className="row gx-0">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
                <div className="about__contact-box d-flex align-items-center">
                <div className="about__contact-icon">
                <span>
                      {" "}
                      <i className="fas fa-phone-alt"> </i>
                    </span>
                  </div>{" "}
                  <div className="about__contact-number">
                    <a href="tel:+447436208590"> +44 7436 208590 </a>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="about__contact-box d-flex align-items-center">
              <div className="about__contact-icon">
              <span>
                      {" "}
                      <i className="fas fa-envelope"> </i>
                    </span>
                  </div>{" "}
                  <div className="about__contact-number">
                    <a href="mailto:webfolioventure@gmail.com">webfolioventure@gmail.com</a>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default About;
